import $ from 'jquery'
import {TAGS} from '../tcity_display_editor/defs';
import moment from 'moment';

// all color tag chars
const COLOR_TAGS = Object.values(TAGS).reduce((out, v)=> {v.color && out.push(v.char); return out}, []);
// all stop tag chars
const STOP_TAGS = Object.values(TAGS).reduce((out, v)=> {v.stop && out.push(v.char); return out}, []);
const STOP_LENGTHS = Object.keys(TAGS).reduce((out, k)=> {TAGS[k].stop && (out[k] = TAGS[k].stop); return out}, {});
// all non stop tag chars
const NON_STOP_TAGS = Object.values(TAGS).reduce((out, v)=> {v.stop || out.push(v.char); return out}, []);
// object: key = tag char, value = tag key
const TAG_KEYS = Object.keys(TAGS).reduce((out, k)=> {out[TAGS[k].char] = k; return out}, {});

const SLIDE_CPS = 8; // slide speed in chars per second

export default class Row {
  constructor(display, row, page, first_page_row) {
    this.display = display;
    this.row = row;
    this.first_page_row = first_page_row;
    this.page = page;
    this.id = `row-${Math.round(Math.random()*10000000)}`
    this.el = $(`<div id="${this.id}" class="preview_row"></div>`);
    
    this.setup();
    this.update();
  }

  get frozen() {
    return this.row.frozen;
  }

  get text() {
    if(this.row.is_news_row) {
      return this.page.editor.news_text;
    } else if(this.frozen && this.first_page_row) {
      return this.first_page_row.value;
    } else {
      return this.row.value;
    }
  }

  get color() {
    // default color;
    if(this.page.editor.support_colors) {
      if(this.row.is_news_row) {
        return this.row.color;
      } else if(this.frozen && this.first_page_row && this.first_page_row.is_news_row) {
        return this.first_page_row.color;
      } else {
        return 'blue';
      }
    } else {
      return 'red';
    }
  }

  get bold(){
    if(this.frozen && this.first_page_row) {
      return this.first_page_row.bold;
    } else {
      return this.row.bold;
    }
  }

  get blink(){
    if(this.frozen && this.first_page_row) {
      return this.first_page_row.blink;
    } else {
      return this.row.blink;
    }
  }

  get slide(){
    if(this.frozen && this.first_page_row) {
      return this.first_page_row.slide;
    } else {
      return this.row.slide;
    }
  }

  get time() {
    return moment().format('HH:mm');
  }

  get date() {
    return moment().format('DD/MM');
  }

  get length() {
    // the text is injected 2 times, to allow smooth sliding
    return this.el.find('> span:first-child').text().length;
  }

  setup() {
    if(!this.modern_browser) this.style = $(`<link rel="stylesheet">`).appendTo(document.head);
    this.row.el.on('row:change', ()=>this.update());
    this.row.el.on('row:toggle:blink', ()=>this.update());
    this.row.el.on('row:toggle:bold', ()=>this.update());
    this.row.el.on('row:toggle:slide', ()=>this.update());
    this.row.el.on('row:toggle:color', ()=>this.update());
    if(this.first_page_row) {
      this.first_page_row.el.on('row:change', ()=>this.update());
      this.first_page_row.el.on('row:toggle:blink', ()=>this.update());
      this.first_page_row.el.on('row:toggle:bold', ()=>this.update());
      this.first_page_row.el.on('row:toggle:slide', ()=>this.update());
      this.first_page_row.el.on('row:toggle:color', ()=>this.update());
    }
    window.setTimeout(()=> this.setTime());
  }
  
  update() {
    this.el.attr('data-color', this.color);
    let text = this.parse(this.text);
    this.el.attr('data-bold', this.bold);
    this.el.html(`<span>${text}</span><span>${text}</span>`);
    this.setupAnimations();
    this.display.sync();
  }

  get modern_browser() {
    return !!this.el.get('0').animate;
  }

  parse(text) {
    this.stops = [];
    // step 1: extract all stops against the full string, with tags replaced with the actual text
    if(text.match(new RegExp(`(${STOP_TAGS.join('|')})`))) {
      let txt1 = text;
      txt1 = txt1.replaceAll(TAGS.time.char, 'xxxxx '); // replace time
      txt1 = txt1.replaceAll(TAGS.date.char, 'xxxxx '); // replace date
      txt1 = txt1.replaceAll(TAGS.temp.char, 'xxxx'); // replace temperature
      txt1 = txt1.replaceAll(TAGS.saint.char, this.page.editor.saint); // replace the saint name
      txt1 = txt1.replaceAll(new RegExp(`(${NON_STOP_TAGS.join('|')})`, 'g'), ''); // strip all non stop tags
      let stops = txt1.split(new RegExp(`(${STOP_TAGS.join('|')})`, 'g'));
      stops = stops.reduce((out, str, i)=> {
        if(STOP_TAGS.indexOf(str) > -1) {
          let before = stops[i-1] || '';
          out.push({tag: TAG_KEYS[str], after: before.length})
        }
        return out;
      }, [])
      this.stops = stops;
    }

    // now strip all the stops chars, we don't need them any more
    text = text.replaceAll(new RegExp(`(${STOP_TAGS.join('|')})`, 'g'), '');
    // add non-breaking-spaces
    text = text.replaceAll(' ', '&nbsp;');

    // step 2: replace all tags in the final text
    let rex = new RegExp(`(${TAGS.time.char}|${TAGS.date.char}|${TAGS.temp.char}|${TAGS.saint.char})`, 'g');
    text = text.replaceAll(rex, (match)=> {
      switch(match) {
        case TAGS.time.char: return `<span class="time">${this.time}&nbsp;</span>`;
        case TAGS.date.char: return `<span class="date">${this.date}&nbsp;</span>`;
        case TAGS.temp.char: return `+24°`;
        case TAGS.saint.char: return this.page.editor.saint;
      }
    });
    
    let open = false;
    rex = new RegExp(`(${COLOR_TAGS.join('|')})`, 'g');
    text = text.replaceAll(rex, (match)=> {
      let wrap = `<span data-color="${TAG_KEYS[match]}">`;
      if(open) wrap = `</span>${wrap}`;
      open = true;
      return wrap;
    });
    if(open) text = `${text}</span>`;
    return text;
  }

  setupAnimations() {
    this.el.attr('data-blink', this.blink);
    if(this.slide) {
      let len = Math.max(this.length, this.page.editor.chars_per_row); // + this.page.editor.chars_per_row;
      let cps = SLIDE_CPS;
      let duration = Math.round(len/cps*1000);
      if(this.bold) {
        len = Math.max(this.length, this.page.editor.chars_per_row/2);
        cps = cps/2;
        duration = Math.round(len/cps*1000);
      }
      // add the number of chars per stop to the duration of the animation
      this.stops.forEach((s)=> {
        if(this.bold) {
          duration += STOP_LENGTHS[s.tag]/2/cps*1000
        } else {
          duration += STOP_LENGTHS[s.tag]/cps*1000
        }
      });

      let animation = [{ transform: 'translateX(0)', offset: 0 }];
      let compatAnimation = ['0% { transform: translateX(0); }'];
      // step 2 add keyframes;
      let choffset = 0;
      let offset = 0;
      this.stops.forEach((s)=>{
        choffset += s.after;
        let off = Math.floor(s.after/cps*1000);
        offset += off/duration;
        animation.push({ transform: `translateX(-${choffset}ch)`, opacity: 1, offset});
        compatAnimation.push(`${Math.floor(offset*10000)/100}% { transform: translateX(-${choffset}ch); opacity: 1;}`);
        off = STOP_LENGTHS[s.tag]/cps*1000;
        if(this.bold) off = STOP_LENGTHS[s.tag]/2/cps*1000;
        if(s.tag == 'stopBlink') {
          let step = off/6
          for(let n = 0; n < 5; n++) {
            offset += step/duration;
            animation.push({ transform: `translateX(-${choffset}ch)`, opacity: (n+1)%2, offset: offset-0.0005});
            compatAnimation.push(`${Math.floor(offset*10000)/100-0.05}% { transform: translateX(-${choffset}ch); opacity: ${(n+1)%2};}`);
            animation.push({ transform: `translateX(-${choffset}ch)`, opacity: n%2, offset});
            compatAnimation.push(`${Math.floor(offset*10000)/100}% { transform: translateX(-${choffset}ch); opacity: ${n%2};}`);
          }
          offset += step/duration;
          animation.push({ transform: `translateX(-${choffset}ch)`, opacity: 0, offset: offset-0.0005});
          compatAnimation.push(`${Math.floor(offset*10000)/100-0.05}% { transform: translateX(-${choffset}ch); opacity: 0;}`);
        } else {
          offset += off/duration;
        }
        animation.push({ transform: `translateX(-${choffset}ch)`, opacity: 1, offset});
        compatAnimation.push(`${Math.floor(offset*10000)/100}% { transform: translateX(-${choffset}ch); opacity: 1; }`);
      });

      animation.push({ transform: `translateX(-${len}ch)`, offset: 1});
      compatAnimation.push(`100% { transform: translateX(-${len}ch); }`);
      
      if(this.modern_browser) {
        this.el.get(0).childNodes.forEach((ch)=> {
          ch.animate(animation,{
            duration: duration,
            iterations: Infinity
          });
        });
      } else {
        let css = `@keyframes anim-${this.id} { 
          ${compatAnimation.join("\n")} 
        }
        .anim-${this.id} { 
          animation-name: anim-${this.id};
          animation-duration: ${duration}ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }`;
        this.style.prop('href', `data:text/css;base64,${btoa(css)}`);
        $(this.el.children()).addClass(`anim-${this.id}`);
      }
    }
  }

  restartAnimation() {
    if(this.modern_browser) {
      this.el.get(0).childNodes.forEach((ch)=>{
        ch.getAnimations().forEach((anim)=> {
          anim.cancel();
          anim.play();
        });
      });
    }
  }

  setTime() {
    this.el.find('span.time').text(`${this.time} `);
    let delay = (new Date()).getSeconds();
    delay = 60 - delay + 1 // seconds until next minute + 1 sec
    window.setTimeout(()=> this.setTime(), delay*1000);
  }
}
