(function($){
	var google_map;
	var infoWindow = null;
	
	window.loadGmap = function(options) {
		google.maps.event.addDomListener(window, 'load', initialize(options));
	}
	
	window.addMarker = function(config, options) {
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(config.lat, config.lng),
			map: google_map,
			draggable: false,
			animation: google.maps.Animation.DROP,
			options : options
		});
		if (config.icon) {
			marker.icon = config.icon;
		}
		
		if (options.content) {
			google.maps.event.addListener(marker, 'click', onMarkerClick);
		}
	}
	
	var onMarkerClick = function() {
		var marker = this;
		var latLng = marker.getPosition();
		
		infoWindow.setContent('<h3>'+marker.title+'</h3>' +
		latLng.lat() + ', ' + latLng.lng() + '<br/><a href="'+marker.url+'" target="_self">'+marker.content+'</a>');
		
		infoWindow.open(google_map, marker);
	};
	
	function initialize(options) {
		if (!options.zoom) { options.zoom = 8; }
  	if (!options.coords) { 
			options.coords = {  // tech address
				lat: 45.610277,
				lng: 8.521723
			}
		}
		var myOptions = {
			zoom: options.zoom,
			center: new google.maps.LatLng(options.coords.lat, options.coords.lng),
			mapTypeId: google.maps.MapTypeId.ROADMAP
		};
		google_map = new google.maps.Map(document.getElementById('map_canvas'), myOptions);
		
		google.maps.event.addListener(google_map, 'click', function() {
			infoWindow.close();
		});
		
		infoWindow = new google.maps.InfoWindow;
	}
	
	/* unused?
	//var geocode_result;
	function geoCode(lat, lng) {
		var geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    
    // borgomanero -> 45.698686,8.462563
    
    geocoder.geocode({'latLng': latlng}, function(results, status) {
			if (status == google.maps.GeocoderStatus.OK) {
				if (results[1]) {
					geocode_result = results[1]; 
        } else {
					geocode_result = null;
        }
      } else {
				geocode_result = null;
      }
    });
    
    return geocode_result;
	}
	
	function parseGeoCodeResult(result, retrive_key) {
		if (result) {
			for(part in result.address_components) {
				if (result.address_components[part].types[0] == retrive_key) {
					return result.address_components[part].long_name
				}
			}	
		}
		return null;
	}
	
	function getLocality(lat, lng) {
		g = geoCode(lat, lng);
		//return parseGeoCodeResult(g, 'locality');
	}
	*/	
})(jQuery);