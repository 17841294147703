// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('core-js/features/object');
require('core-js/features/string');

const Rails = window.Rails = require("@rails/ujs")
Rails.start();
//require("turbolinks").start()
//require("@rails/activestorage").start()
require("channels")
//window.consumer = require("channels/consumer")

const jQuery = require('jquery')
window.jQuery = jQuery;
window.$ = jQuery;


jQuery(function(){
  $('select:not(.nojs)').select2({theme: 'bootstrap4'});
});

require('bootstrap')
require('admin-lte')
window.moment  = require('moment')
window.moment.locale('it')
window.toastr  = require('admin-lte/plugins/toastr/toastr.min.js')
require('admin-lte/plugins/select2/js/select2')

require.context('../images', true)
require('../javascripts/polyfill')
require('../javascripts/logs')
require('../javascripts/gmap')
require('../javascripts/tcity_display_preview')
require('../javascripts/tcity_display_editor')
require('../stylesheets/application.scss')

require('jquery-ui');
require('jquery-ui-dist/jquery-ui.css')
require('jquery-timepicker/jquery.timepicker.js');
require('jquery-timepicker/jquery.timepicker.css');

window.ModalConfirm = require('../javascripts/tcity_display_editor/modal_confirm').default;
window.PositionEditor = require('../javascripts/position_editor').default;



