import $ from 'jquery';
import Hexdump from './libs/hexdump';
var base64js = require('base64-js')

$(function(){
  $(document.body).on('click', '.log-toggler', function(ev) {
    let log = $(this).closest('tr').find('.log-container-full');
    let prev = $(this).closest('tr').find('.log-container');
    if(prev.is(':visible')) {
      log.show();
      prev.hide();
      $(this).find('.fa-eye').hide();
      $(this).find('.fa-eye-slash').show();
    } else {
      prev.show();
      log.hide();
      $(this).find('.fa-eye').show();
      $(this).find('.fa-eye-slash').hide();
    }
    ev.preventDefault();
  });

  $(document.body).on('click', '.show-bin-log', function(ev) {
    let data = $(this).data('bin');

    let modal = $('#display-bin-modal');
    if(!modal.is('div')) {
      modal = $(`
        <div class="modal fade modal-hexdump" tabindex="-1">
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Dati binari</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body p-0">
              </div>
              <div class="modal-footer">
                <a href="data:application/octet-stream;base64,${data}" download="data.bin" class="btn btn-primary"><i class="fas fa-download"></i> Scarica</a>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
              </div>
            </div>
          </div>
        </div>    
      `);
      modal.appendTo(document.body);
    }
    new Hexdump(base64js.toByteArray(data), modal.find('.modal-body'));
    modal.modal({show: true});
  });
});