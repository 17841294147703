import $ from 'jquery';
import {sprintf} from 'sprintf-js';
const ROW = 16;

export default class HexDump {
  constructor(data, container) {
    this.data = data;
    this.container = container;

    this.render();
  }

  render() {
    let container = this.container;
    let count = Math.ceil(this.data.length/16);
    let hexdump = $('<div class="hexdump hd"></div>').appendTo(container);
    
    for(let n = 0; n < count; n++) {
      let data = this.data.subarray(n*ROW, (n+1)*ROW);

      let row = $('<div class="hd--row"></div>');
      let lineno = $(sprintf(`<div class="hd--lineno">%04X</div>`, n*ROW));
      row.append(lineno);
      let hex = $('<div class="hd--hex"></div>');
      row.append(hex);
      let txt = $('<div class="hd--txt"></div>');
      row.append(txt);

      data.forEach((byte, i)=> {
        hex.append($(sprintf(`<span data-byte="%d" class="hd--hex--byte">%02X</span>`, n*ROW+i, byte)));
        txt.append($(sprintf(`<span data-byte="%d" class="hd--txt--byte">%s</span>`, n*ROW+i, this.techscii(byte))));
      })

      row.appendTo(hexdump);
    }
    

    container.find('[data-byte]').on('mouseenter', function(){
      let n = $(this).data('byte');
      container.find('.hover').removeClass('hover');
      container.find(`[data-byte="${n}"]`).addClass('hover');
    });
    container.find('.hd--hex, .hd--txt').on('mouseleave', function(){
      container.find('.hover').removeClass('hover');
    });
    container.on('mouseleave', function(){
      container.find('.hover').removeClass('hover');
    });
  }

  techscii(byte) {
    if(byte > 0x20 && byte < 0x7f) {
      return String.fromCharCode(byte);
    } else {
      switch(byte) {
        case 0x20: return "&nbsp;"; break;
        case 0x80: return "€"; 
        case 0xA1: return '¡'; 
        case 0xA2: return '¢'; 
        case 0xA3: return '£'; 
        case 0xA4: return '¤'; 
        case 0xA5: return '¥'; 
        case 0xA6: return '¦'; 
        case 0xA7: return '§'; 
        case 0xA8: return '¨'; 
        case 0xA9: return '©'; 
        case 0xAA: return 'ª'; 
        case 0xAB: return '«'; 
        case 0xAC: return '¬'; 
        case 0xAD: return '­&shy;';
        case 0xAE: return '®'; 
        case 0xAF: return '¯'; 
        case 0xB0: return '°'; 
        case 0xB1: return '±'; 
        case 0xB2: return '²'; 
        case 0xB3: return '³'; 
        case 0xB4: return '´'; 
        case 0xB5: return 'µ'; 
        case 0xB6: return '¶'; 
        case 0xB7: return '·'; 
        case 0xB8: return '¸'; 
        case 0xB9: return '¹'; 
        case 0xBA: return 'º'; 
        case 0xBB: return '»'; 
        case 0xBC: return '¼'; 
        case 0xBD: return '½'; 
        case 0xBE: return '¾'; 
        case 0xBF: return '¿'; 
        case 0xC0: return 'À'; 
        case 0xC1: return 'Á'; 
        case 0xC2: return 'Â'; 
        case 0xC3: return 'Ã'; 
        case 0xC4: return 'Ä'; 
        case 0xC5: return 'Å'; 
        case 0xC6: return 'Æ'; 
        case 0xC7: return 'Ç'; 
        case 0xC8: return 'È'; 
        case 0xC9: return 'É'; 
        case 0xCA: return 'Ê'; 
        case 0xCB: return 'Ë'; 
        case 0xCC: return 'Ì'; 
        case 0xCD: return 'Í'; 
        case 0xCE: return 'Î'; 
        case 0xCF: return 'Ï'; 
        case 0xD0: return 'Ð'; 
        case 0xD1: return 'Ñ'; 
        case 0xD2: return 'Ò'; 
        case 0xD3: return 'Ó'; 
        case 0xD4: return 'Ô'; 
        case 0xD5: return 'Õ'; 
        case 0xD6: return 'Ö'; 
        case 0xD7: return '×'; 
        case 0xD8: return 'Ø'; 
        case 0xD9: return 'Ù'; 
        case 0xDA: return 'Ú'; 
        case 0xDB: return 'Û'; 
        case 0xDC: return 'Ü'; 
        case 0xDD: return 'Ý'; 
        case 0xDE: return 'Þ'; 
        case 0xDF: return 'ß'; 
        case 0xE0: return 'à'; 
        case 0xE1: return 'á'; 
        case 0xE2: return 'â'; 
        case 0xE3: return 'ã'; 
        case 0xE4: return 'ä'; 
        case 0xE5: return 'å'; 
        case 0xE6: return 'æ'; 
        case 0xE7: return 'ç'; 
        case 0xE8: return 'è'; 
        case 0xE9: return 'é'; 
        case 0xEA: return 'ê'; 
        case 0xEB: return 'ë'; 
        case 0xEC: return 'ì'; 
        case 0xED: return 'í'; 
        case 0xEE: return 'î'; 
        case 0xEF: return 'ï'; 
        case 0xF0: return 'ð'; 
        case 0xF1: return 'ñ'; 
        case 0xF2: return 'ò'; 
        case 0xF3: return 'ó'; 
        case 0xF4: return 'ô'; 
        case 0xF5: return 'õ'; 
        case 0xF6: return 'ö'; 
        case 0xF7: return '÷'; 
        case 0xF8: return 'ø'; 
        case 0xF9: return 'ù'; 
        case 0xFA: return 'ú'; 
        case 0xFB: return 'û'; 
        case 0xFC: return 'ü'; 
        case 0xFD: return 'ý'; 
        case 0xFE: return 'þ'; 
        case 0xFF: return 'ÿ'; 
        default: return '.';
      }
    }
  }
}