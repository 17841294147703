import $ from 'jquery';

let TEMPLATE = `
  <div class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel btn-secondary" data-dismiss="modal">Annulla</button>
          <button type="button" class="btn btn-confirm btn-primary" data-dismiss="modal">Ok</button>
        </div>
      </div>
    </div>
  </div>
`

let fn = function({title, body, callback, severity = null, ...options}) {
  let modal = $(TEMPLATE).appendTo(document.body);
  modal.find('.modal-title').text(title);
  modal.find('.modal-body').html(body);
  if(severity) {
    modal.find('.modal-header').addClass(`bg-${severity}`);
    modal.find('.modal-footer .btn-confirm').removeClass('btn-primary').addClass(`btn-${severity}`);
  }

  var confirmed = false;
  modal.one('hide.bs.modal', ()=> {
    if(!confirmed) {
      modal.trigger('modal:confirm', false);
      if(callback) callback(false);
    }
    modal.remove();
  });
  modal.find('.btn-confirm').one('click', ()=> {
    confirmed = true;
    modal.trigger('modal:confirm', true);
    if(callback) callback(true);
  });
  modal.modal(Object.assign({}, {show: true}, options));
  return modal;
}

export default fn;