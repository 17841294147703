
jQuery(function(){
  if(!Modernizr.inputtypes["datetime-local"]) {
    $('input[type="datetime-local"]').each(function(){
      var dateTimeEl = $(this);
      dateTimeEl.attr('type', 'hidden');
      var dateEl = dateTimeEl.clone().attr('id', null).attr('type', 'date').attr('name', '').addClass('datetime-local-date');
      var timeEl = dateTimeEl.clone().attr('id', null).attr('type', 'time').attr('name', '').addClass('datetime-local-time');
      dateEl.insertAfter(dateTimeEl);
      timeEl.insertAfter(dateEl);
      
      var val = $(dateTimeEl).val();
      if(val) {
        val = moment(val).local();
        dateEl.val(val.format('YYYY-MM-DD'));
        timeEl.val(val.format('HH:mm'));
      }
      
      dateTimeEl.on('change', function(){
        var val = $(this).val();
        if(val) {
          val = moment(val).local();
          dateEl.val(val.format('YYYY-MM-DD'));
          if(!Modernizr.inputtypes["date"]) {
            console.log('lippa!')
            dateEl.datepicker('setDate', val.format('DD/MM/YYYY'));
          }
          timeEl.val(val.format('HH:mm'));
        } else {
          dateEl.val('');
          timeEl.val('');
        }
      });
      
      dateEl.on('change', function(){
        var date = $(this).val();
        if(!Modernizr.inputtypes["date"]) {
          date = moment($(this).datepicker('getDate')).format('YYYY-MM-DD');
        }
        var time = timeEl.val();
        if(date) {
          if(time) {
            date += 'T' + time;
          } else {
            date += 'T00:00';
          }
          console.log(date)
          dateTimeEl.val(moment(date).utc().format());
        } else {
          dateTimeEl.val('');
          timeEl.val('');
        }
      });
      
      timeEl.on('change timepicker:change', function(){
        var time = $(this).val();
        var date = dateEl.val();
        if(!Modernizr.inputtypes["date"]) {
          date = moment(dateEl.datepicker('getDate')).format('YYYY-MM-DD');
        }
        if(date) {
          if(time) {
            date += 'T' + time;
          } else {
            date += 'T00:00';
          }
          console.log(date)
          dateTimeEl.val(moment(date).utc().format());
        } else {
          dateTimeEl.val('');
        }
      });      

      if(!Modernizr.inputtypes["date"]) {
        dateEl.datepicker({
          dateFormat: 'dd/mm/yy'
        });
        if(val) {
          dateEl.datepicker('setDate', val.format('DD/MM/YYYY'));
        }
      }
      if(!Modernizr.inputtypes["time"]) {
        timeEl.timepicker({
          timeFormat: 'HH:mm',
          interval: 10,
          minTime: '0',
          maxTime: '23:59',
          dynamic: false,
          dropdown: false,
          scrollbar: true,
          change: function() {
            timeEl.trigger('timepicker:change')
          }
        });
      }
    });    
  }
});