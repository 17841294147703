import Editor from './editor';
import $ from 'jquery';

$.fn.tcitydisplayeditor = function(options) {
  let node = $(this).get(0);
  let editor = $(node).data('displayeditor');
  if(!editor && node) {
    editor = new Editor(node, options);
    $(node).data('displayeditor', editor);
  }
  return editor;
};

