import $ from 'jquery'
import Row from './row'

export default class DisplayPreview {
  constructor(el, page) {
    this.el = $(el);
    this.page = page;
    this.rows = [];
    this.setup();
  }

  get modern_browser() {
    return !!this.el.get('0').animate;
  }

  setup() {
    this.page.rows.forEach((row, i)=> {
      let first_page_row = null;
      if(this.page.editor.pages[0] && this.page !== this.page.editor.pages[0]){
        first_page_row = this.page.editor.pages[0].rows[i];
      }
      let drow = new Row(this, row, this.page, first_page_row);
      this.rows.push(drow);
      this.el.find('.preview_box').append(drow.el);
    });
    this.el.attr('data-width', this.page.editor.chars_per_row);
    this.el.find('.preview_box').addClass('show');
    window.setTimeout(()=> {
      this.el.removeClass('loading');
      this.doSync();
    }, 50);
  }

  sync() {
    if(this.modern_browser) {
      this.doSync()
    } else {
      window.setTimeout(()=> {
        this.doSync();
      }, 250);
    }
  }
  
  doSync() {
    // force reflow
    this.el.hide();
    this.el.width();
    this.el.show();
    this.rows.forEach((r)=> r.restartAnimation())
  }
}