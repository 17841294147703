import consumer from "./consumer";
const $ = require('jquery');

window.channels.Display.LogUpdates = {
  update(id) {
    consumer.subscriptions.create({channel: "Display::LogUpdatesChannel", id: id}, {
      received(log) {
        let btn = '';
        if(log.binary) {
          btn = `
          <a href="#" class="show-bin-log" data-bin="${log.log}">
            <i class="fas fa-eye"></i>
          </a>
          `;
        } else if(log.log.length > 100) {
          btn = `
          <a href="#" class="log-toggler">
            <i class="fas fa-eye"></i>
            <i class="fas fa-eye-slash" style="display:none"></i>
          </a>
          `;
        }

        let log_msg = '[Dati binari]';

        if(!log.binary) {
          if(log.log.length > 100) {
            log_msg = `
            <div class="log-container">${log.log.slice(0,100)}</div>
            <div class="log-container-full" style="display:none">
              ${log.log.replace(/ /g, '&nbsp;').replace(/(\r|\n|\r\n)/g, '<br>')}
            </div>
            `;
          } else {
            log_msg = log.log
          }
        }

        $('.logs-table tbody').prepend($(`
        <tr>
          <td>${log.created_at}</td>
          <td>
            <span class="badge badge-${this.alert_style(log)}">${log.alert_type}</span>
          </td>
          <td>
            ${log.title}
          </td>
          <td>
            ${btn}
          </td>
          <td>
            ${log_msg}
          </td>
        </tr>
        `))
      },

      alert_style(log) {
        if(log.alert_type) {
          if(log.alert_type.match(/ack/)) return 'success';
          if(log.alert_type.match(/check/)) return 'secondary';
          if(log.alert_type.match(/error/)) return 'danger';
          if(log.alert_type.match(/serving/)) return 'info';
          if(log.alert_type.match(/warning/)) return 'warning';
        }
        return 'secondary';
      }

    });
  },
}