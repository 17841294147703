import { position as caretPosition } from 'caret-pos';
import $ from 'jquery'
import History from './history'
import {TAGS, DEPRECATED_TAGS} from './defs'

function truth(v) {
  return v && (v == 1 || v == '1' || v == 'true')
}

const MAX_LINE_LENGTH = 200;

// all chars in ore array
const TAG_CHARS = Object.values(TAGS).map((v)=> v.char);
// object: key = tag char, value = tag key
const TAG_KEYS = Object.keys(TAGS).reduce((o, k)=> {o[TAGS[k].char] = k; return o}, {});
// object: key = tag char, value = tag len
const TAG_LENGTHS = Object.keys(TAGS).reduce((o, k)=> {o[TAGS[k].char] = TAGS[k].len || 0; return o}, {})
// all color tag chars
const COLOR_TAGS = Object.values(TAGS).reduce((a, v)=> {v.color && a.push(v.char); return a}, []);
// all tags that force slide
const SLIDE_TAGS = Object.values(TAGS).reduce((a, v)=> {v.slide && a.push(v.char); return a}, []);

export default class Row {  
  constructor(page, node, row) {
    this.el = $(node);
    this.row = Object.assign({}, row);
    this.page = page;
    this._frozen = false;
    this.setup();
  }

  get frozen() {
    return this.is_news_row || this._frozen;
  }

  get is_news_row() {
    return this.row.last_row && this.page.editor.support_news_feed;
  }

  get support_saint() {
    return this.page.editor.support_saint;
  }

  get text() {
    return this.values.text.val();
  }
  set text(v) {
    return !this.frozen && this.values.text.val(v);
  }
  
  get bold() {
    return truth(this.values.bold.val());
  }
  set bold(v) {
    v = truth(v);
    if(v) {
      this.controls.bold.addClass('active');
      this.editor.attr('data-bold', true);
    } else {
      this.controls.bold.removeClass('active');
      this.editor.attr('data-bold', false);
    }
    this.values.bold.val(v)
    this.el.trigger('row:toggle:bold', [this, v]);
    return v;
  }

  get blink() {
    return truth(this.values.blink.val());
  }
  set blink(v) {
    v = truth(v);
    if(v) {
      this.controls.blink.addClass('active');
    } else {
      this.controls.blink.removeClass('active');
    }
    this.values.blink.val(v);
    this.el.trigger('row:toggle:blink', [this, v]);
    return v;
  }

  get slide() {
    let v = truth(this.values.slide.val());
    if(this.is_news_row) v = true;
    return v;
  }
  set slide(v) {
    v = truth(v);
    if(v) {
      this.blink = false;
      this.controls.blink.addClass('disabled');
      this.controls.alignL.addClass('disabled');
      this.controls.alignC.addClass('disabled');
      this.controls.alignR.addClass('disabled');
      this.controls.slide.addClass('active');
    } else {
      this.controls.blink.removeClass('disabled');
      this.controls.alignL.removeClass('disabled');
      this.controls.alignC.removeClass('disabled');
      this.controls.alignR.removeClass('disabled');
      this.update(this.stripSlideTags(this.value));
      this.controls.slide.removeClass('active');
    }
    this.values.slide.val(v);
    this.el.trigger('row:toggle:slide', [this, v]);
    return v;
  }

  get color() {
    return this.values.color.val();
  }
  set color(v) {
    v = v ? v : 'blue';
    this.controls.colors.removeClass('active');
    if(this.is_news_row) {
      window.setTimeout(()=>this.controls.colors.filter(`[data-color="${v}"]`).addClass('active'));
      this.editor.attr('data-color', v);
      this.values.color.val(v);
      this.el.trigger('row:toggle:color', [this, v]);
    }
    return v;
  }

  get value() {
    let val = this.clamp(this.editor.text());
    return val;
  }

  set value(v) {
    let val = this.clamp(v);
    this.update(val);
    this.history.reset(val);
    this.triggerChange();
    return this.text;
  }

  get length() {
    let val = this.value;
    if(this.__countCharsCache != `${this.editor.text()}|${this.bold}|${this.slide}`) {
      let len = this.valueLength(val);
      this.__countCharsCache = `${val}|${this.bold}|${this.slide}`;
      this.__countCharsCachedValue = len;
    }
    return this.__countCharsCachedValue || 0;
  }

  get maxLength() {
    let len = this.page.editor.chars_per_row;
    if(this.slide) { len = MAX_LINE_LENGTH; }
    if(this.bold)  { len /=2;  }
    return len;
  }

  get modern_browser() {
    return !!this.el.get('0').animate;
  }

  setup() {
    this.input = this.el.find('input[name*="[text]"]');
    this.editor = this.el.find('.form-control .text-editor');
    this.indicator = this.el.find('.input-group-text');
    this.controls = {
      bold:       this.el.find('.btn-bold'),
      slide:      this.el.find('.btn-slide'),
      blink:      this.el.find('.btn-blink'),
      stopBlink:  this.el.find('.btn-opt-slide-sl'),
      stop1:      this.el.find('.btn-opt-slide-s1'),
      stop2:      this.el.find('.btn-opt-slide-s2'),
      stop3:      this.el.find('.btn-opt-slide-s3'),
      date:       this.el.find('.btn-date'),
      time:       this.el.find('.btn-time'),
      temp:       this.el.find('.btn-temp'),
      saint:      this.el.find('.btn-saint'),
      dropdowns:  this.el.find('.dropdown-toggle'),
      colors:     this.el.find('.btn[data-color]'),
      alignL:     this.el.find('.btn-align-left'),
      alignC:     this.el.find('.btn-align-center'),
      alignR:     this.el.find('.btn-align-right'),
    }

    this.values = {
      text: this.el.find('input[name*="[text]"]'),
      bold: this.el.find('input[name*="[bold]"]'),
      blink: this.el.find('input[name*="[blink]"]'),
      slide: this.el.find('input[name*="[slide]"]'),
      color: this.el.find('input[name*="[color]"]')
    }

    this.controls.colors.closest('.btn-toolbar').hide();
    this.controls.saint.hide();

    if(this.page.editor.support_colors) {
      this.el.find('.form-control').attr('data-support-colors', true);
      this.controls.colors.closest('.btn-toolbar').show();
    }
    if(this.page.editor.support_saint) this.controls.saint.show();
    
    this.text = this.stripLegacyTags(this.text);
    this.history = new History(this.text);

    this.setupControls();
    this.update(this.text);
    this.updateIndicator();
        
    // trigger controls update 
    this.bold = this.bold;
    this.blink = this.blink;
    this.slide = this.slide;
    this.color = this.color;

    this.setupEvents();
  }

  setupEvents() {
    if(!this.modern_browser) {
      this.fireInputEvent();
    }
    this.editor.on('input', (ev)=> {
      let t = ev && ev.originalEvent && ev.originalEvent.inputType;
      if( t == 'historyUndo') {
        ev.preventDefault();
        this.undo();
      } else if( t == 'historyRedo') {
        ev.preventDefault();
        this.redo();
      } else {
        this.update();
        this.history.push(this.text);
      }
      this.triggerChange();
    });
    this.editor.on('keypress', (ev)=> {
      if(ev.ctrlKey && (ev.key == 'y' || ev.key == 'z')) {
        ev.preventDefault();
        if(ev.key == 'z') this.undo();
        if(ev.key == 'y') this.redo();
        this.triggerChange();
      }
    });
    this.controls.bold.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.bold = !this.bold;
      this.update();
    })
    this.controls.slide.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.slide = !this.slide;
      this.update(this.value.trim());
    })
    this.controls.blink.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.blink = !this.blink;
      this.update();
    })
    this.controls.stopBlink.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('stopBlink');
    })
    this.controls.stop1.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('stop1');
    })
    this.controls.stop2.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('stop2');
    })
    this.controls.stop3.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('stop3');
    })
    this.controls.date.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('date');
    })
    this.controls.time.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('time');
    })
    this.controls.temp.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('temp');
    })
    this.controls.saint.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.focus();
      this.addTag('saint');
    })

    this.controls.alignL.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.align('left')
    })
    this.controls.alignC.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.align('center')
    })
    this.controls.alignR.on('click', (ev)=>{
      ev.preventDefault();
      if($(ev.delegateTarget||ev.target).is('.disabled')) return;
      this.align('right')
    })

    this.controls.colors.on('click', (ev)=>{
      ev.preventDefault();
      this.focus();
      if(this.is_news_row) {
        this.color = $(ev.delegateTarget||ev.target).data('color');
        this.update();
      } else {
        this.addTag($(ev.delegateTarget||ev.target).data('color'));
      }
    })
  }

  stripLegacyTags(text) {
    // compat
    // bold tag, NOT at the end of the line
    let bold = !!text.trim().match(/<B>.+/);
    if(bold) this.bold = true;

    // replace all deprecated tags
    Object.keys(DEPRECATED_TAGS).forEach((tag)=> {
      var key = DEPRECATED_TAGS[tag];
      text = text.replaceAll(tag, TAGS[key].char);
    });

    return text;
  }

  formatText(text) {
    // html entities for < & >
    text = text.replace(/\s/g, '&nbsp;');
    text = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    
    // saint name not supported: strip tag
    if(!this.support_saint) {
      text = text.replaceAll(TAGS.saint.tag, '');
    }

    let rex = new RegExp(`(${COLOR_TAGS.join('|')})`, 'g'); // /(🟢|🟡|🔵|....)/

    if(this.page.editor.support_colors) {
      // pass 1: split at emojis
      let parts = text.split(rex).filter((p)=> p.length > 0);
      // pass 2: remove duplicates/adjacent color emojis
      parts = parts.filter((p,i)=> !(p.match(rex) && parts[i+1] && parts[i+1].match(rex)))
      // pass 3: wrap in spans, one per color
      let first = true, found = false;
      parts = parts.map((p, i)=> {
        if(COLOR_TAGS.indexOf(p) > -1) {
          p = `<span data-color="${TAG_KEYS[p]}">${p}`
          if(!first) p = `</span>${p}`;
          first = false, found = true;
        }
        return p;
      });
      if(found) parts.push('</span>');
      text = parts.join('');
    } else {
      text = text.replace(rex, '');
    }

    // wrap all emojis with a <i> tag
    TAG_CHARS.forEach((emo)=> {
      text = text.replace(new RegExp(`(${emo})`, 'g'), `<i data-tag="${TAG_KEYS[emo]}" class="${TAGS[TAG_KEYS[emo]].class}"><span>$1</span></i>`);
    });

    return text;
  }

  update(val) {
    let force = !!(typeof val != 'undefined' && val !== null);
    let newVal = (typeof val != 'undefined' && val !== null) ? this.clamp(val) : this.value;
    if(this.frozen) {
      if(this.is_news_row) {
        newVal = "[NEWS]";
      } else {
        newVal = "[BLOCCATO]";
      }
    }
    this.preserveCaret(()=> {
      // use raw editable value, vam might be cut to length
      let key = `${this.editor.text()}|${this.bold}|${this.slide}|${this.color}|${this.frozen}`;
      if(force || this.__updateCache != key) {
        this.editor.html(this.formatText(newVal));
        this.text = this.value;
        this.__updateCache = `${this.text}|${this.bold}|${this.slide}|${this.color}|${this.frozen}`;
      }
    });
    this.updateIndicator();
    this.checkSliding();
  }
  
  updateIndicator() {
    let len = this.length;
    this.indicator.removeClass('bg-success bg-warning bg-danger');
    let cls = 'bg-success';
    if(len >= this.maxLength) {
      cls = 'bg-danger';
    } else if (len >= this.maxLength*.75) {
      cls = 'bg-warning';
    }
    this.indicator.addClass(cls);
    this.indicator.text(len);
  }

  addTag(key) {
    let tag = TAGS[key];
    this.focus();
    window.setTimeout(()=> {
      this.addTagAtCaret(tag.char);
      this.triggerChange();
    })
  }

  addTagAtCaret(tag) {
    let sel = window.getSelection();
    let range = sel.getRangeAt(0);
    range.deleteContents();

    let frag = document.createDocumentFragment();
    let node = document.createTextNode(tag)
    frag.appendChild(node);
    frag = range.insertNode(frag);

    this.focus();
    this.update();
  }

  clamp(text) {
    let maxl = this.maxLength;
    let ntext = []
    let len = 0;
    // cannot use string split, emojis are 4 bytes long.... javascrap
    let split = [...text];
    split.some((c)=> {
      len += TAG_LENGTHS[c] > -1 ? TAG_LENGTHS[c] : 1;
      if(len > maxl) return true;
      ntext.push(c);
      return false;
    });
    return ntext.join('');
  }

  preserveCaret(callback){
    let pos = null;
    let ed = this.editor.get(0);
    if(!this.frozen && this.editor.is(':focus')) pos = caretPosition(ed);
    callback()
    if(!this.frozen && this.editor.is(':focus')) {
      caretPosition(ed, Math.min(ed.textContent.length, pos.pos));
    }
  }

  focus() {
    this.editor.trigger('focus');
  }

  undo() {
    this.history.back();
    this.update(this.history.current);
  }

  redo() {
    this.history.fwd();
    this.update(this.history.current);
  }

  triggerChange() {
    if(this.__triggerCache != this.value) {
      this.el.trigger('row:change', this, this.value);
      this.__triggerCache == this.value;
    }
  }

  freeze() {
    this._frozen = true;
    this.update();
    this.setupControls();
  }

  thaw() {
    this._frozen = false;
    this.update(this.text);
    this.setupControls();
  }

  setupControls() {
    if(this.frozen) {
      this.values.text.prop('disabled', true);
      this.editor.prop('contenteditable', false);
      this.indicator.parent().hide();
      Object.values(this.controls).forEach((c)=> c.addClass('disabled').prop('disabled', true))
      if(this.is_news_row) {
        this.controls.colors.removeClass('disabled').prop('disabled', false)
      }
    } else {
      this.values.text.prop('disabled', false);
      this.editor.prop('contenteditable', true);
      this.indicator.parent().show();
      Object.values(this.controls).forEach((c)=> c.removeClass('disabled').prop('disabled', false))
    }
  }

  checkSliding() {
    let rex = new RegExp(`(${SLIDE_TAGS.join('|')})`);
    if(this.text.match(rex)) {
      this.slide = true;
    }
  }

  fireInputEvent() {
    let cache = this.editor.text();
    window.setInterval(()=>{
      if(cache != this.editor.text()) {
        this.editor.trigger('input');
      }
      cache = this.editor.text();
    }, 100);
  }

  stripSlideTags(text) {
    let rex = new RegExp(`(${SLIDE_TAGS.join('|')})`, 'g')
    return text.replaceAll(rex, '');
  }

  valueLength(txt) {
    return [...txt].reduce((l, c) => l += typeof TAG_LENGTHS[c] == 'undefined' ? c.length : TAG_LENGTHS[c], 0);
  }

  align(dir) {
    let txt = this.value.trim();
    let len = this.valueLength(txt);
    let diff = this.maxLength - len;
    switch(dir) {
      case 'center':
        diff = Math.floor(diff/2);
      case 'right': 
        txt = `${[...Array(diff+1)].join(' ')}${txt}`; 
    }
    this.focus();
    this.update(txt);   
    this.triggerChange();
  }
}