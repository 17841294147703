import consumer from "./consumer";
const $ = require('jquery');

let msg_toast = null;
let status_toast = null;
window.channels.Display.UpdateStatus = {
  wait(id, msg) {
    if(msg) {
      msg_toast = toastr.success(msg, null, {iconClass: 'toast-success toast-fa spin', timeOut: 0, extendedTimeout: 0});
    }    

    consumer.subscriptions.create({channel: "Display::UpdateStatusChannel", id: id}, {
      received(data) {
        if(data.status) {
          if(msg_toast) {
            msg_toast.fadeOut();
            msg_toast = null;
          }
          if(status_toast) {
            status_toast.fadeOut();
            status_toast = null;
          }

          if(data.status == 'ok') {
            status_toast = toastr.success('Display aggiornato');
            if(data.timestamp) {
              console.log(data.timestamp)
              $('#last-ack').text(moment(data.timestamp).format('D MMMM YYYY HH:mm'));
            }
          } else if(data.status == 'fail') {
            status_toast = toastr.warning('Il display non ha ricevuto l\'aggiornamento. Nuovo tentativo in un minuto.');
          }
        } else {
          if(status_toast) {
            status_toast.fadeOut();
            status_toast = null;
          }
          if(!msg_toast) {
            msg_toast = toastr.success(data.message, null, {iconClass: 'toast-success toast-fa spin', timeOut: 0, extendedTimeout: 0});
          }
        }
      }
    });
  },
}