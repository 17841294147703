import Page from './page'
import $ from 'jquery'
import modal_confirm from './modal_confirm';

const MESSAGE = `
<p>Siete sicuro di voler <strong>cancellare tutte le pagina</strong> e <strong>svuotare definitivamente la prima</strong>?</p>
</div>
<p>L'operazione <strong>non è reversibile</strong> ed ha effetto <strong>immediato</strong>.</p>`;

export default class Editor {
  constructor(node, options) {
    console.log(options);
    this.el = $(node);
    this.options = options.options;
    this.pages = [];

    this.el.find('.tab-pane .editor-page').each((i, node)=>{
      i += 1; // usa indici base 1....
      this.createPage(node, options.pages[i]);
    });
    this.setupEvents();
  }

  get max_pages() {
    return this.options.max_pages;
  }

  get new_page_url() {
    return this.options.url;
  }

  get destroy_page_url() {
    return this.options.url_destroy;
  }

  get num_rows() {
    return this.options.num_rows;
  }

  get support_news_feed() {
    return this.options.support_news_feed;
  }

  get support_saint() {
    return this.options.support_saint;
  }

  get chars_per_row() {
    return this.options.chars_per_row;
  }

  get support_colors() {
    return this.options.support_colors;
  }

  get news_text() {
    return this.options.news;
  }

  get saint() {
    let text = this.options.saint;
    if(this.options.single_saint) {
      text = text.split(',')[0].trim()
    }
    return text;
  }

  createPage(node, data) {
    var page = new Page(this, node, data);
    this.pages.push(page);
  }

  setupEvents() {
    $('#remove-all-pages').on('click', ()=>{
      modal_confirm({title: 'Attenzione', body: MESSAGE, severity: 'danger', callback: (confirm) => {
        if(confirm) this.removeAll();
      }});
    });

    $('#add-page').on('click', ()=>{
      this.addPage();
    });
  }

  addPage() {
    if(this.pages.length < this.max_pages) {
      $.ajax({
        context: this,
        url : this.new_page_url,
        type : 'GET',
        success: function(response, textStatus, jqXHR) {
          let idx = this.pages.length + 1;
          $('#editor_widget .nav-tabs li:last').after(`<li id="li-${idx}" class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab-pag-${idx}">Pagina ${idx}</a></li>`);
          let node = $(`<div class="tab-pane active in" id="tab-pag-${idx}">${response}</div>`);
          $('#editor_widget .tab-pane:last').after(node);

          $('#editor_widget .nav-tabs a:last').tab('show');
          $('#removePage').removeClass('disabled');

          let data = {
            active: true, 
            permanency_time: 3,
            rows: [...Array(this.num_rows)].map(()=> ({bold: false, slide: false, blink: false, text: "", color: "blue"}) )
          };
          this.createPage(node, data);
          if(this.pages.length >= this.max_pages) {
            $('#add-page').addClass('disabled');
          }

          $('#remove-all-pages').prop('disabled', false).removeClass('disabled');
        }
      });
    }
  }

  removeAll() {
    this.pages.forEach((page, i)=> {
      if(i == 0) {
        // clear first page
        page.clear();
      } else {
        // add _destroy=true param
        let name = page.el.find('input.model_pages_attributes_id').prop('name');
        name = name.replace(/\[id\]$/, '[_destroy]');
        $(`<input type="hidden" name="${name}" value="1">`).appendTo(page.el);
      }
    });
    this.el.closest('form').trigger('submit');
  }
}
