export default class History {
  constructor(initial) {
    this.reset(initial);
  }

  get current() {
    return this.values[this.pointer];
  }

  reset(val) {
    this.initial = val;
    this.values = [val];
    this.pointer = 0;
  }

  push(val) {
    if(val != this.values[this.pointer]) {
      this.values = this.values.slice(0, this.pointer+1);
      this.values.push(val);
      this.pointer++;
    }
  }

  back() {
    this.pointer = Math.max(this.pointer-1, 0);
  }

  fwd() {
    this.pointer = Math.min(this.pointer+1, this.values.length-1);    
  }
}