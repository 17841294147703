import Row from './row'
import $ from 'jquery'
import modal_confirm from './modal_confirm';
import DisplayPreview from '../tcity_display_preview';

const CLEAR_PAGE_MESSAGE =`
<p>Siete sicuro di voler cancellare il contenuto della pagina?</p>
<p>L'operazione <strong>è reversibile SOLAMENTE se non si salva il modello dopo aver cancellato il contenuto</strong>.</p>
<p>In caso di errore basterà ricaricare la pagina.</p>`;

const REMOVE_PAGE_MESSAGE =`
<p>Siete sicuro di voler <strong>cancellare la pagina</strong> corrente?</p>
<p>L'operazione <strong>non è reversibile</strong> ed ha effetto <strong>immediato</strong>.</p>`;

export default class Page {
  constructor(editor, node, page) {
    this.el = $(node);
    this.editor = editor;
    this.page = Object.assign({}, page);
    this.id = this.el.find('input.model_pages_attributes_id').val();
    this.rows = [];

    this.el.find('.row-editor').each((i, node)=>{
      i += 1; // usa indici base 1....
      let row = this.page.rows[i];
      row = Object.assign({}, row, {index: i, last_row: i == this.editor.num_rows});
      this.addRow(node, row);
    });

    this.setupEvents();
    this.setupDisplay();
  }
  
  get url_destroy() {
    return this.editor.destroy_page_url;
  }
  
  addRow(node, data) {
    let row = new Row(this, node, data);
    this.rows.push(row);
    let idx = this.rows.length-1;
    
    // here, the page wasn't still pushed in the stack
    // if pages.length is 1, this is the 2nd page
    if(this.editor.pages.length > 0) {
      if(this.editor.pages[0].rows[idx].slide) row.freeze();
    }
    
    $(node).on('row:change', (ev, row, val)=> {
      this.el.trigger('page:row:change', [row, idx, val]);
    });
    $(node).on('row:toggle:blink', (ev, row, val)=> {
      this.el.trigger('page:row:toggle:blink', [row, idx, val]);
    });
    $(node).on('row:toggle:bold', (ev, row, val)=> {
      this.el.trigger('page:row:toggle:bold', [row, idx, val]);
    });
    $(node).on('row:toggle:slide', (ev, row, val)=> {
      this.el.trigger('page:row:toggle:slide', [row, idx, val]);
    });
    $(node).on('row:toggle:color', (ev, row, val)=> {
      this.el.trigger('page:row:toggle:color', [row, idx, val]);
    });
  }
  
  setupDisplay() {
    this.display = new DisplayPreview(this.el.find('.display_preview'), this);
  }

  setupEvents() {
    if(this.editor.pages.length > 0) {      
      this.editor.pages[0].el.on('page:row:toggle:slide', (ev,row,idx,val)=> {
        if(val) {
          this.rows[idx].freeze();
        } else {
          this.rows[idx].thaw();
        }
      })
    }

    this.el.find('.clear-page').on('click', ()=>{
      modal_confirm({title: 'Attenzione', body: CLEAR_PAGE_MESSAGE, callback: (confirm) => {
        if(confirm) this.clear();
      }});
    });

    this.el.find('.remove-page').on('click', ()=>{
      modal_confirm({title: 'Attenzione', body: REMOVE_PAGE_MESSAGE, severity: 'danger', callback: (confirm) => {
        if(confirm) this.remove();
      }});
    });
  }

  clear() {
    this.rows.forEach((row)=> {
      row.blink = false;
      row.bold = false;
      row.slide = false;
      row.color = 'blue';
      row.value = '';
    });
  }

  remove() {
    let data = {      
      'id' : $('#customer_display_id').val(),
      format: 'json',
      app_disp_model: {
        pages_attributes: [{
          id : this.id,
          '_destroy' : 1
        }]
      }
    };
    if($('meta[name="csrf-param"]').prop('content') && $('meta[name="csrf-token"]').prop('content')) {
      data[$('meta[name="csrf-param"]').prop('content')] = $('meta[name="csrf-token"]').prop('content');
    }

    $.ajax({
      url: this.url_destroy,
      method: 'PATCH',
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(data),
      success: function(response, textStatus, jqXHR) {
        window.location.reload();
      }
    });
  }
}
