const DEPRECATED_TAGS = {
  '<DATA>':  'date',
  '<ORA >':  'time',
  '<TP>':    'temp',
  '<SAINT>': 'saint',
  '<CW>': 'white', 
  '<CR>': 'red', 
  '<CB>': 'blue', 
  '<CG>': 'green', 
  '<CY>': 'yellow', 
  '<CC>': 'cyan', 
  '<CM>': 'magenta',
  '<SL>': 'stopBlink',
  '<S1>': 'stop1',
  '<S2>': 'stop2',
  '<S3>': 'stop3',
};

const TAGS = {
  date:      {char: "\uf073", len: 6, class: "far", slide: false },
  time:      {char: "\uf017", len: 6, class: "far", slide: false },
  temp:      {char: "\uf769", len: 4, class: "fas", slide: false },
  saint:     {char: "\uf654", len: 0, class: "fas", slide: true},
  stopBlink: {char: "\uf159", len: 0, class: "fas", slide: true,  stop: 24}, // stop is in chars
  stop1:     {char: "\uf15a", len: 0, class: "fas", slide: true,  stop: 24}, // stop is in chars
  stop2:     {char: "\uf15b", len: 0, class: "fas", slide: true,  stop: 48}, // stop is in chars
  stop3:     {char: "\uf15c", len: 0, class: "fas", slide: true,  stop: 72}, // stop is in chars
  red:       {char: "\uf150", len: 0, class: "fas", slide: false, color: true },
  green:     {char: "\uf151", len: 0, class: "fas", slide: false, color: true },
  yellow:    {char: "\uf152", len: 0, class: "fas", slide: false, color: true },
  blue:      {char: "\uf153", len: 0, class: "fas", slide: false, color: true },
  white:     {char: "\uf154", len: 0, class: "fas", slide: false, color: true },
  cyan:      {char: "\uf155", len: 0, class: "fas", slide: false, color: true },
  magenta:   {char: "\uf156", len: 0, class: "fas", slide: false, color: true },
};

export {TAGS, DEPRECATED_TAGS};