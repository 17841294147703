import $ from 'jquery';
import Rails from '@rails/ujs';

const DEFAULT_LAT = 45.610277;
const DEFAULT_LNG = 8.521723;

function debounce(cb, interval) {
  if(cb._debounce) window.clearTimeout(cb._debounce);
  cb._debounce = window.setTimeout(()=>{
    cb()
    cb._debounce = null;
  }, interval);
}

function makeDropDown(input) {
  let dd = $('<div class="dropdown"></div>');
  dd.appendTo(document.body);
  let ddm = $('<div class="dropdown-menu"></div>');
  ddm.prependTo(dd);
  let ddt = $('<span data-toggle="dropdown"></span>');
  ddt.prependTo(dd);
  $(input).data('dropdown', ddm);
  $(input).data('dropdown-toggle', ddt);
  dd.on('click', '.dropdown-item', function(ev){
    $(input).trigger('select:dd', $(ev.target).data('value'));
  });
}

function showDropDown(input, data) {
  let ddm = $(input).data('dropdown');
  if(typeof data != 'undefined') {
    ddm.html('');    
    let html = data.slice(0,5).forEach((d)=> {
      let item = $(`<a href="#" class="dropdown-item">${d.address}</a>`).data('value', d);
      item.appendTo(ddm);
    });
  }
  if(ddm.children().length > 0) {
    $(input).data('dropdown-toggle').dropdown('show');
    let off = input.offset();
    let wrapOff = ddm.closest('.dropdown').offset();
    ddm.css({zIndex: 100000, width: input.outerWidth(true), top: off.top - wrapOff.top + input.outerHeight(true), left: off.left - wrapOff.left});
  }
}
function hideDropDown(input) {
  $(input).data('dropdown-toggle').dropdown('hide');
}

export default function(modal) {
  let modalEl = $(modal).modal({show: true, backdrop: 'static'});
  let latInput = modal.find('input[name*="latitude"]');
  let lngInput = modal.find('input[name*="longitude"]');
  let addrInput = modal.find('input[name*="full_address"]');
  let latLabel = modal.find('span.lat');
  let lngLabel = modal.find('span.lng');
  let mapEl = modal.find('.map');
  let map = null;
  let pin = null;

  let cache = addrInput.val().toLowerCase();

  let _geolocate = function(){
    let query = addrInput.val();
    if(query) query = query.trim();
    if(query && cache != query.toLowerCase() && query.length >= 3) {
      let xhr = $.ajax({
        method: 'POST',
        url: '/common/locations/geolocate',
        dataType: 'json',
        beforeSend: Rails.CSRFProtection,
        data: {address: query}
      });
      
      xhr.done((response)=> {
        showDropDown(addrInput, response);
      });
      cache = query;
    }
  };
  let geolocate = function(){
    debounce(_geolocate, 1000);
  };
  let updateFromMap = function(pos) {
    let lat = Math.round(pos.lat()*1000000)/1000000;
    let lng = Math.round(pos.lng()*1000000)/1000000;
    latInput.val(lat);
    latLabel.text(lat);
    lngInput.val(lng);
    lngLabel.text(lng);
  }
  let update = function(value) {
    addrInput.val(value.address);
    latInput.val(value.lat);
    latLabel.text(value.lat);
    lngInput.val(value.lng);
    lngLabel.text(value.lng);
    map.setCenter({lat: value.lat, lng: value.lng});
    pin.setPosition({lat: value.lat, lng: value.lng});
    map.setZoom(16);
  };


  modalEl.on('shown.bs.modal', ()=>{
    map = mapEl.data('gmap');
    pin = mapEl.data('gmap-pin');
    if(!map) {
      map = new google.maps.Map(mapEl.get(0), {
        zoom: 14,
      });
      pin = new google.maps.Marker({
        draggable: true,
        map,
      });
      mapEl.data('gmap', map);
      mapEl.data('gmap-pin', pin);
      makeDropDown(addrInput);
      addrInput.on('keypress', function(ev) {
        if(ev.originalEvent.code == "Enter") {
          ev.preventDefault()
        };
      });
      addrInput.on('keyup', function(ev) {
        if(ev.originalEvent.code == "KeyDown") {
          showDropDown(addrInput);
        } else {
          geolocate();
        }
      });
      addrInput.on('select:dd', function(ev, value){
        if(value) {
          update(value);
        }
        hideDropDown(addrInput);
      });
      pin.addListener('drag', ()=>{
        updateFromMap(pin.getPosition());
      });
      pin.addListener('dragend', ()=>{
        updateFromMap(pin.getPosition());
      });
    }
    map.setCenter({lat: parseFloat(latInput.val()) || DEFAULT_LAT, lng: parseFloat(lngInput.val()) || DEFAULT_LNG});
    pin.setPosition({lat: parseFloat(latInput.val()) || DEFAULT_LAT, lng: parseFloat(lngInput.val()) || DEFAULT_LNG});
    latLabel.text(latInput.val() || DEFAULT_LAT);
    lngLabel.text(lngInput.val() || DEFAULT_LNG);
  });

  modalEl.on('hide.bs.modal', ()=>{
    
  });
};