import consumer from "./consumer"
const $ = require('jquery')

window.channels.Display.ConnectionStatus = {
  index() {
    let ids = Array.from($('table [data-display]').map((idx, i)=> $(i).data('display')));
    if( ids.length > 0) {
      consumer.subscriptions.create({channel: "Display::ConnectionStatusChannel", ids: ids}, {
        received(data) {
          let id, status;
          ({id, status} = data);
          let row = $(`table tr[data-display="${id}"]`);
          if(row.is('tr')) {
            if(status) {
              row.removeClass().addClass('hw-connected');
              row.find('td:first-child').html('<i class="fas fa-check-square text-success"></i>');
            } else {
              row.removeClass().addClass('hw-disconncted');
              row.find('td:first-child').html('<i class="fas fa-exclamation-triangle text-warning"></i>');
            }
          }
        }
      });
    }
  },

  display() {
    let ids = Array.from($('.card[data-display]').map((idx, i)=> $(i).data('display')));
    if( ids.length > 0) {
      consumer.subscriptions.create({channel: "Display::ConnectionStatusChannel", ids: ids}, {
        received(data) {
          let id, status;
          ({id, status} = data);
          let box = $(`.card[data-display="${id}"]`);
          if(box.is('.card')) {
            if(status) {
              box.removeClass().addClass('card card-success bg-success');
              box.find('td:first-child').html('<i class="fas fa-check-square text-success"></i>');
              box.find('.is-connected').show();
              box.find('.is-disconnected').hide();
            } else {
              box.removeClass().addClass('card card-warning bg-warning');
              box.find('td:first-child').html('<i class="fas fa-exclamation-triangle text-warning"></i>');
              box.find('.is-connected').hide();
              box.find('.is-disconnected').show();
            }
          }
        }
      });
    }
  },
}